function formatFullDate(date) {
    const d = new Date(date);

    if (isNaN(d.getTime())) {
        return 'Invalid Date'; // Mengembalikan pesan jika tanggal tidak valid
    }

    // Array nama hari dan bulan dalam bahasa Indonesia
    const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    const months = [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
        'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ];

    const dayName = days[d.getDay()];
    const day = d.getDate().toString().padStart(2, '0');
    const monthName = months[d.getMonth()];
    const year = d.getFullYear();

    // Format hasil akhir
    return `${dayName}, ${day} ${monthName} ${year}`;
}

export { formatFullDate };